import React, { useMemo, useRef, useState } from "react"
import { Menu } from "primereact/menu"
import ImgCustom from "src/components/common/img-custom"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import coinIcon from "src/assets/images/setting-menu-coin.svg"
import shipIcon from "src/assets/images/setting-menu-ship-icon.svg"
import socialIcon from "src/assets/images/setting-social-module.svg"
import shopIcon from "src/assets/images/setting-shop-module.svg"
import foodIcon from "src/assets/images/setting-food-module.svg"
import flightIcon from "src/assets/images/setting-flight-module.svg"
import hotelIcon from "src/assets/images/setting-hotel-module.svg"
import carIcon from "src/assets/images/setting-car-module.svg"
import tourIcon from "src/assets/images/setting-tours-module.svg"
// import myBookingIcon from "src/assets/images/setting-my-booking.svg"
import saveListIcon from "src/assets/images/setting-save-list.svg"
import partnerIcon from "src/assets/images/setting-partner-center.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import shopModuleIcon from "src/assets/images/shop-module.webp"
import carModuleIcon from "src/assets/images/car-module.webp"
import tourModuleIcon from "src/assets/images/tour-module.webp"
import hotelModuleIcon from "src/assets/images/hotel-module.webp"
import socialModuleIcon from "src/assets/images/social-module.webp"
import restaurantModuleIcon from "src/assets/images/restaurant-module.webp"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import i18n from "src/locales"
// import programIcon from "src/assets/images/setting-program.svg"
const moduleData = [
  {
    icon: socialIcon,
    label: "Social",
    url: `${process.env.REACT_APP_HOME_DOMAIN_URL}`,
  },
  {
    icon: shopIcon,
    label: "Shopping",
    url: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}`,
  },
  {
    icon: foodIcon,
    label: "Foods",
    url: `${process.env.REACT_APP_FOOD_DOMAIN_URL}`,
  },
  {
    icon: flightIcon,
    label: "Flights",
    url: `${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}/booking/flight`,
  },
  {
    icon: hotelIcon,
    label: "Hotels",
    url: `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}`,
  },
  {
    icon: carIcon,
    label: "Cars",
    url: `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`,
  },
  {
    icon: tourIcon,
    label: "Tours",
    url: `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}`,
  },
]

const SettingView = () => {
  const authStore = useSelector((state: RootState) => state.auth)
  const userMenuRef = useRef<Menu>(null)
  const centralMenuRef = useRef<Menu>(null)
  const [isShow, setIsShow] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const avatarImg = useMemo(() => {
    return authStore?.value ? authStore?.value.user?.avatar_thumbnail_url : ""
  }, [authStore])
  const getCoinValue = useMemo(() => {
    return 0
  }, [])
  const goToModule = (moduleUrl: string) => {
    if (moduleUrl === "shop") {
      navigate("/shopping")
    } else window.open(moduleUrl, "_self")
  }
  const goToPage = (pageUrl: string) => {
    window.open(pageUrl, "_self")
  }
  const goToView = (viewUrl: string, isLocal: boolean) => {
    if(isLocal) {
      navigate(viewUrl)
    } else {
      window.location.href = viewUrl
    }
  }

  const viewData = useMemo(() => {
    return   [
      {
        icon: "sctr-icon-file-02",
        label: capitalizedFirst(t("header.reservation")),
        url: "/food/orders",
        isLocal: true,
        key: `order-${new Date().getTime()}`,
      },
      {
        icon: "sctr-icon-wallet-02",
        label: capitalizedFirst(t("header.wallet")),
        url: process.env.REACT_APP_MY_WALLET_DOMAIN_URL,
        isLocal: false,
        key: `wallet-${new Date().getTime()}`,
      },
    ]
  }, [i18n.language])

  const pageData = useMemo(() => {
    return   [
      // {
      //   icon: myBookingIcon,
      //   label: "My bookings",
      //   url: `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}/my-booking/hotel`,
      // },
      {
        icon: saveListIcon,
        label: capitalizedFirst(t("header.save-list")),
        url: `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}/saved-list`,
        key: `saved-${new Date().getTime()}`,
      },
      {
        icon: partnerIcon,
        label: capitalizedFirst(t("header.central")),
        url: process.env.REACT_APP_BUSINESS_CENTRAL_DOMAIN_URL,
        key: `central-${new Date().getTime()}`,
        isCentral: true,
      },
      // {
      //   icon: programIcon,
      //   label: "Affiliate program",
      // url: ""
      // },
    ]
  }, [i18n.language])
  if (!authStore?.value) return null

  const dataCentral = [
    {
      label: t("menu-item.shop"),
      link: `${process.env.REACT_APP_BUSINESS_CENTRAL_DOMAIN_URL}/home?isShowShopDialog=true`,
      icon: shopModuleIcon,
    },
    {
      label: t("menu-item.hotel"),
      link: `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}/ota/hotel-ota`,
      icon: hotelModuleIcon,
    },
    {
      label: t("menu-item.car"),
      link: `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car/become-agency`,
      icon: carModuleIcon,
    },
    {
      label: t("menu-item.tour"),
      link: `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}/request-become-agency`,
      icon: tourModuleIcon,
    },
    {
      label: t("menu-item.social"),
      link: `${process.env.REACT_APP_HOME_DOMAIN_URL}`,
      icon: socialModuleIcon,
    },
    {
      label: t("menu-item.restaurant"),
      link: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/request-open-restaurant`,
      icon: restaurantModuleIcon,
    },
  ]

  const itemsCentral = [
    {
      template: () => {
        return (
          <div className="flex flex-col bg-white rounded-3 p-1">
            {dataCentral.map((i) => (
              <>
                <a href={i.link} className="flex items-center cursor-pointer hover:bg-gray-300 gap-1">
                  <img src={i.icon} className="w-[24px] h-[24px]"/>
                  <span className="text-gray-700 font-medium text-[16px] leading-24px">{i.label}</span>
                </a>
              </>
            ))}
          </div>
        )
      },
    },
  ]

  const items = [
    {
      template: () => {
        return (
          <div className="flex flex-col bg-gray-100">
            <div className="relative flex h-[178px] flex-col rounded-t-3">
              <div className="h-[116px] w-full rounded-t-3 bg-[linear-gradient(99.24deg,#1879FF_12.47%,#0658CC_98.43%)]"></div>
              <div className="w-full flex-1 bg-white"></div>
              <div className="absolute left-[16px] top-[44px] flex w-[calc(100%-32px)] flex-col gap-1 rounded-3 border border-gray-200 bg-white p-1">
                <div className="flex items-center justify-between gap-[12px]">
                  <div className="flex items-center gap-[12px]">
                    <div
                      className="h-[40px] w-[40px] rounded-full border-[2px] border-orange-500"
                      onClick={(e) => userMenuRef?.current?.toggle(e)}
                    >
                      <ImgCustom
                        isAvatar={true}
                        alt="userAvatar"
                        url={avatarImg as string}
                        className="border-whit h-full w-full rounded-full border object-cover object-center"
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                        {authStore.value?.user?.full_name}
                      </span>
                      <span className="text-[12px] leading-[16px] text-gray-500">
                        {authStore.value?.user?.email || ""}
                      </span>
                    </div>
                  </div>
                  <i className="sctr-icon-qr-code-02 cursor-pointer text-[24px] text-blue-600" />
                </div>

                <div className="flex gap-[12px]">
                  <div className="flex flex-1 items-center justify-between rounded-3 bg-amber-50 px-[12px] py-1">
                    <div className="flex flex-col">
                      <span className="text-[16px] font-semibold leading-[24px] text-gray-900">
                        {capitalizedFirst(t("header-setting.coin"))}
                      </span>
                      <span className="text-[14px] leading-[20px] text-gray-700">
                        {getCoinValue}
                      </span>
                    </div>
                    <img src={coinIcon} alt="coinIcon" className="h-[28px] w-[28px]" />
                  </div>

                  <div className="flex flex-1 items-center justify-between rounded-3 bg-red-50 px-[12px] py-1">
                    <span className="text-[16px] font-semibold leading-[24px] text-gray-900">
                      {capitalizedFirst(t("header-setting.ship"))}
                    </span>
                    <img src={shipIcon} alt="coinIcon" className="h-[32px] w-[32px]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-4 gap-1 bg-white px-1">
                {moduleData.map((i) => (
                  <div
                    onClick={() => goToModule(i.url as string)}
                    key={i.label}
                    className="flex cursor-pointer flex-col items-center justify-center gap-2px py-1 hover:bg-blue-50"
                  >
                    <img className="h-[40px] w-[40px]" src={i.icon} alt={i.label} />
                    <span className="text-[12px] font-medium leading-[16px] text-gray-700 ">
                      {t(`header-setting.${i.label}`)}
                    </span>
                  </div>
                ))}
              </div>

              <div className="mt-4px flex flex-col bg-white px-1">
                {pageData.map((i) => (
                  <div
                    onMouseEnter={(e) => handleEnterView(e, i?.isCentral || false)}
                    onClick={() => goToPage(i.url as string)}
                    key={i.key}
                    className="flex cursor-pointer items-center justify-between gap-4px hover:bg-blue-50"
                  >
                    <div className="flex items-center gap-[12px] px-[12px] py-1">
                      <img className="h-[28px] w-[28px]" alt={i.label} src={i.icon} />
                      <span className="text-[14px] font-medium leading-[20px] text-gray-900">
                        {i.label}
                      </span>
                    </div>
                    {
                      i.label === capitalizedFirst(t("header.central")) ? (
                        <>
                          <i className="sctr-icon-chevron-right text-[24px]"/>
                          <Menu model={itemsCentral} popup ref={centralMenuRef} className=" translate-x-[380px] mt-[-45px] w-[140px]"/>
                        </>
                      ) : null
                    }
                  </div>
                ))}
              </div>

              <div className="mt-4px flex flex-col bg-white px-1">
                {viewData.map((i) => (
                  <div
                    onClick={(e) => {
                      userMenuRef?.current?.toggle(e)
                      goToView(i.url as string, i.isLocal)
                    }}
                    key={i.key}
                    className="flex cursor-pointer items-center justify-between gap-4px hover:bg-blue-50"
                  >
                    <div className="flex items-center gap-[12px] px-[12px] py-1">
                      <i className={`text-[28px] ${i.icon}`} />
                      <span className="text-[14px] font-medium leading-[20px] text-gray-900">
                        {i.label}
                      </span>
                    </div>
                    <i className="sctr-icon-chevron-right text-[24px]" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      },
    },
  ]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEnterView = (e:any, isCentral: boolean) => {
    if(isCentral) centralMenuRef?.current?.show(e)
  }
  return (
    <>
      <div
        className={`flex h-[36px] w-[36px] cursor-pointer items-center justify-center ${
          !isShow ? "text-gray-500" : "text-blue-600"
        }`}
        onClick={(e) => userMenuRef?.current?.toggle(e)}
      >
        <i className="sctr-icon-dots-grid text-[20px]" />
      </div>
      <Menu
        onShow={() => setIsShow(true)}
        onHide={() => setIsShow(false)}
        model={items}
        popup
        ref={userMenuRef}
        className="mt-1 w-full rounded-3 md:w-[384px]"
      />
    </>
  )
}

export default SettingView
